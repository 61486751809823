<template>
    <div class="package-index">
        <md-tabs @change="tabChange">
            <md-tab md-label="Packages" :md-active="tabIndex == 0">
                <product-list v-if="tabIndex == 0" type="Package"></product-list>
            </md-tab>
            <md-tab md-label="Add Package" :md-disabled="user.role == 'staff' && !hasPermission('edit_product')" :md-active="tabIndex == 1">
                <product-edit v-if="tabIndex == 1" type="Package"></product-edit>
            </md-tab>
            <md-tab md-label="Edit Package" v-if="tabIndex == 2" :md-active="tabIndex == 2">
                <product-edit :id="id" v-if="tabIndex == 2" type="Package"></product-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductEdit from "@/components/Product/ProductEdit";
import ProductList from "@/components/Product/ProductList";
export default {
    props: ["id"],
    components: {
        ProductList,
        ProductEdit
    },
    data() {
        return {
            tabIndex: 0
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            hasPermission: "user/hasPermission"
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else {
                    this.tabIndex = 2;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (index == 0) {
                this.$router.push("/admin/packages");
            }
            if (index == 1) {
                this.$router.push("/admin/packages/new");
            }
            if (index == 2) {
                this.$router.push(`/admin/packages/${this.id}`);
            }
        }
    }
};
</script>
